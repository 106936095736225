<template>
  <div class="installationContactList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(contacts)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(contacts)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="notificationCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div style="display: flex; justify-content: center;">
              <JiraNotificationStatus
                :jira-notification-media="props.dataItem.notificationMedia"
              />
              /
              <template>
                <font-awesome-icon
                  v-if="props.dataItem.keycloakRoles && (props.dataItem.keycloakRoles.includes('scope_staff') ||
                    props.dataItem.keycloakRoles.includes('scope_customer'))"
                  v-tooltip="props.dataItem.keycloakRoles.toString()"
                  icon="door-open"
                  size="2x"
                />
                <font-awesome-icon
                  v-else-if="props.dataItem.isInKeycloak && (!props.dataItem.keycloakRoles || (props.dataItem.keycloakRoles &&
                    !(props.dataItem.keycloakRoles.includes('scope_staff') ||
                      props.dataItem.keycloakRoles.includes('scope_customer'))))"
                  v-tooltip="props.dataItem.keycloakRoles ? props.dataItem.keycloakRoles.toString() : 'no roles'"
                  icon="door-closed"
                  size="2x"
                  style="color: blue;"
                />
                <font-awesome-icon
                  v-else-if="!props.dataItem.isInKeycloak"
                  icon="door-closed"
                  size="2x"
                  style="color: black;"
                />
              </template>
            </div>
          </td>
        </template>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="phoneFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="phoneFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a number"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('phoneFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="emailFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="emailFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a email"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('emailFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="roleFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="roleFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a role"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('roleFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div
              v-if="authenticationHasRole('scope_staff') || authenticationHasRole('secondlevel')"
              class="btn-group-vertical"
            >
              <div class="btn-group">
                <a
                  class="btn btn-primary btn-sm"
                  :href="jiraHref(props.dataItem.id)"
                  target="_blank"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                  <span>{{ $t('edit') }}</span>
                </a>
                <a
                  class="btn btn-danger btn-sm"
                  :href="jiraHref(props.dataItem.id)"
                  target="_blank"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="trash"
                  />
                  <span>{{ $t('delete') }}</span>
                </a>
              </div>
              <a
                class="btn btn-secondary btn-sm"
                :href="jiraHref(props.dataItem.id)"
                target="_blank"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('removeFromInstallation') }}</span>
              </a>
            </div>
            <div
              v-else-if="authenticationHasRole('scope_customer') && !authenticationHasRole('secondlevel')"
              class="btn-group-vertical"
            >
              <div class="btn-group">
                <button
                  class="btn btn-primary btn-sm"
                  @click="showSidebarEdit(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="edit"
                  />
                  <span>{{ $t('edit') }}</span>
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="showSidebarDelete(props.dataItem)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="trash"
                  />
                  <span>{{ $t('delete') }}</span>
                </button>
              </div>
              
              <button
                class="btn btn-secondary btn-sm"
                @click="showSidebarRemove(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('removeFromInstallation') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <FreshdeskCustomerTicketAdd
          v-if="addinglement"
          :subject="subject"
          :installation-id="installationId"
          @close="close"
        />
        <FreshdeskCustomerTicketAdd
          v-else-if="editinglement"
          :subject="subject"
          :contact="selectedContact"
          :installation-id="installationId"
          :jira-url="jiraHref(selectedContact.id)"
          @close="close"
        />
        <FreshdeskCustomerTicketAdd
          v-else-if="deletingElement"
          :subject="subject"
          :contact="selectedContact"
          :installation-id="installationId"
          :jira-url="jiraHref(selectedContact.id)"
          @close="close"
        />
        <FreshdeskCustomerTicketAdd
          v-else-if="removingElement"
          :subject="subject"
          :contact="selectedContact"
          :installation-id="installationId"
          :jira-url="jiraHref(selectedContact.id)"
          @close="close"
        />
      </Sidebar>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { mapGetters } from 'vuex';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import Sidebar from '@/components/Base/Sidebar.vue';

export default {
  name: "InstallationContactList",
  components: {
    JiraNotificationStatus: () => import('@/components/Jira/JiraNotificationStatus.vue'),
    FreshdeskCustomerTicketAdd: () => import('@/components/Freshdesk/FreshdeskCustomerTicketAdd.vue'),
    Sidebar
  },
  mixins: [kendoGridMixin, urlParseMixin, authenticationMixin],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      contacts: null,
      showSidebar: false,
      addinglement: false,
      editinglement: false,
      removingElement: false,
      deletingElement: false,
      selectedContact: null,
      subject: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "key",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_sort: [
        {
          field: "key",
          dir: "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'fullName',
          filterable: true,
          title: 'Name',
          filterCell: 'nameFilter',
        },
        {
          field: 'phone',
          filterable: true,
          title: this.$t('phone'),
          filterCell: 'phoneFilter',
          hideOn: ['xsDown']
        },
        {
          field: 'email',
          filterable: true,
          title: this.$t('email'),
          filterCell: 'emailFilter',
          hideOn: ['xsDown']
        },
        {
          field: 'role',
          filterable: true,
          title: 'Role',
          filterCell: 'roleFilter',
          hideOn: ['xsDown']
        },
        {
          field: 'notificationMedia',
          filterable: false,
          title: `${this.$t('contactNotificationMedia')} / Metis Login`,
          cell: 'notificationCell',
          hideOn: ['xsDown']
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['store_getJiraUrl']),
  },
  created () {
    this.getContacts();
  },
  methods: {
    jiraHref: function (id) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ id }`);
    },
    getName (first, last) {      
      if(first != null & last != null) {
        return first + ' ' + last;
      }
      else if(first != null && !last) {
        return first;
      }
      else if(!first && last != null) {
        return last;
      }

      return '';
    },
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.subject="add";
      this.editinglement = false;
      this.removingElement = false;
      this.deletingElement = false;
      this.addinglement = true;
      this.show();
    },
    showSidebarEdit (contact) {
      this.setSelectedContact(contact);
      this.subject="edit";
      this.editinglement = true;
      this.removingElement = false;
      this.deletingElement = false;
      this.addinglement = false;
      this.show();
    },
    showSidebarDelete (contact) {
      this.setSelectedContact(contact);
      this.subject="delete";
      this.deletingElement = true;
      this.removingElement = false;
      this.editinglement = false;
      this.addinglement = false;
      this.show();
    },
    showSidebarRemove (contact) {
      this.setSelectedContact(contact);
      this.subject="remove";      
      this.removingElement = true;
      this.deletingElement = false;
      this.editinglement = false;
      this.addinglement = false;
      this.show();
    },
    setSelectedContact (contact) {
      this.selectedContact = Object.assign({}, contact);
    },
    //api calls
    clearCache () {
      this.loading = true;
      this.axios.delete('/Contact/CleanupInstallationCache?installationId=' + this.installationId)
        .then(() => {
          this.getContacts()
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getContacts () {
      this.loading = true;
      this.axios.get('/Contact/GetContacts/' + this.installationId)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.contacts = response.data;
          this.contacts.forEach(e => {
            e.fullName = this.getName(e.firstname, e.surname);
            e.phone = e.phone != null ? e.phone.replaceAll(" ", "") : null;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style>
.installationContactList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>